import * as React from "react";
import { Content } from "../components/Content"
import dayContent from '../lang/geo.json';
import Radio from '../assets/vectors/radio.svg';
import MapsMenu from '../assets/vectors/maps-menu.svg';
import { StoryNavigation } from "../components/StoryNavigation";
import { Statistics } from "../components/Statistics";
import { NavigationTop } from "../components/NavigationTop";
import { MapButton } from "../components/MapButton";
import { NavigationMobile } from "../components/NavigationMobile";
import Victims from '../assets/vectors/victims.svg'
import HomeIcon from '../assets/vectors/Home_SVG.svg'
import { MenuTopMobile } from "../components/MenuTopMobile";
import { VideoPlayer } from "../components/Overlay";
import { ProgressBar } from "../components/ProgressBar";
import videos from '../assets/json/video.json';
import { MapContent } from "../components/MapContent";
import Video2SrcMp4 from '../assets/videos/video2.mp4';
import Video2SrcWebm from '../assets/videos/video2.webm';
import { Helmet } from "react-helmet";
import { Playlist } from "../components/Playlist";
import { useSwipeable } from 'react-swipeable';
import debounce from 'lodash.debounce';
import { PlaySound } from "../components/PlaySound";
import { useDispatch, useSelector } from 'react-redux';
import { getClasses } from "../helpers/exports";
import { Backgrounds } from "../components/Backgrounds";
import { DayNavigation } from "../components/DayNavigation";
import { navigate } from "gatsby";

const anchors = ['intro','day-1', 'day-1-2', 'day-2', 'day-2-2', 'day-2-3', 'day-2-4', 'day-2-5', 'day-3', 'day-3-2', 'day-3-3', 'day-3-4', 'day-3-5', 'day-4','day-4-2','day-4-3','day-4-4','day-5','day-5-2','day-5-3','day-6','day-6-2', 'day-7','day-7-2','day-7-3','day-7-4','day-7-5','day-7-6','day-7-7','day-7-8','day-7-9', 'day-8','day-8-2','day-8-3','day-8-4','day-8-5','day-8-6','day-8-7','day-8-8','day-8-9','day-9','day-9-2','day-9-3','day-9-4','day-9-5','day-9-6','day-9-7','day-9-8', 'day-10','day-10-2','day-10-3','day-10-4','day-10-5','day-10-6','day-10-7', 'day-11','day-11-2','day-11-3','day-11-4','day-11-5','day-11-6','day-11-7','day-11-8', 'day-12','day-12-2','day-12-3','day-12-4','day-12-5','day-12-6','day-12-7','day-12-8','day-13','day-13-2','day-13-3','day-13-4', 'day-14','day-14-2', 'day-15','day-15-2','day-15-3','day-15-4','day-15-5','day-15-6', 'stats', 'outro'];

const SMALL_WINDOW_WIDTH = 576;

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = React.useState();
  React.useEffect(() => {
    function handleResize() {
      setWindowWidth(window.outerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {

      window.removeEventListener("resize", handleResize)
    };
  }, []);
  return windowWidth;
}

const IndexPage = () => {

  const {loaded, musicState} = useSelector((state) => state)

  const [currentView, setCurrentView] = React.useState(0);
  const [showMap, setShowMap] = React.useState(false);
  const [overlay, setOverlay] = React.useState('');
  const [videoPlaying, setVideoPlaying] = React.useState('');
  const [showMobile, setShowMobile] = React.useState(false);
  const [ isContentScrolled, setIsContentScrolled ] = React.useState(false);
  const containerRef = React.useRef(null);
  const dispatch = useDispatch();
  const width = useWindowWidth();

  React.useEffect(() => {
    dispatch({ type: 'CHANGE_LANGUAGE', payload: { lang: 'ge' }});
  }, [dispatch])

  const goToPrevious = () => {
    setIsContentScrolled(false)
    
    if (currentView > 0) {
      setCurrentView(currentView - 1);
    }
    containerRef.current.scroll({
      top: 0,
      behavior: 'smooth'
    });
  }

  const goToNext = () => {
    setIsContentScrolled(false)

    if (currentView < anchors.length - 1) {
      setCurrentView(currentView + 1);
    }
    containerRef.current.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => goToNext(),
    onSwipedRight: () => goToPrevious(),
    delta: 20
  })

  React.useEffect(() => {
    const currentViewIndex = anchors.findIndex((element) => element === window.location.hash.slice(1));
    if (currentViewIndex > -1) {
      setCurrentView(currentViewIndex);
    }
  }, []);

  React.useEffect(() => {
    if (containerRef && containerRef.current) {
      const el = containerRef.current;
      el.addEventListener('transitionend', onContentScroll)
      
      return () => {
        el && el.removeEventListener('transitionend', onContentScroll);
      }
    }

  }, [containerRef])

  React.useEffect(() => {
    onContentScroll()
  }, [loaded]);

  React.useEffect(() => {
    setShowMobile(width < SMALL_WINDOW_WIDTH )
  }, [width])

  React.useEffect(() => {
    const handleScroll = (event) => {
      if(!showMap) {
        if (event.deltaY < 0 && currentView > 0)
        {
          setCurrentView(currentView - 1);
        }
        else if (event.deltaY > 0 && currentView < anchors.length)
        {
          setCurrentView(currentView + 1)
        }
      }
    };

    const debouncedhandleScroll = debounce(handleScroll, 600);

    window.addEventListener("wheel", debouncedhandleScroll, { passive: true });

    return () => {
      window.removeEventListener("wheel", debouncedhandleScroll);
    }
  }, [currentView, showMap]);

  React.useEffect(() => {
    window.location.hash = anchors[currentView];
  }, [currentView])

  React.useEffect(() => {
    function musicListener() {
      dispatch({ type: 'SET_LOADED' });
    }
     window.addEventListener('musicClick', musicListener)

    return () => {
      window.removeEventListener('musicClick', musicListener);
    }
  }, [dispatch])


  const onClickDown = () => {
    goToPrevious();
  }

  const onClickUp = () => {
    goToNext();
  }

  const onHashChange = React.useCallback((hash) => {
    if (containerRef && containerRef.current) {
      containerRef.current.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }
    const currentViewIndex = anchors.findIndex((element) => element === hash);
    setCurrentView(currentViewIndex);
  }, [])

  const changeCurrentView = (view) => {
    setCurrentView(view);
  }

  const onMapButtonClick = () => {
    setShowMap(!showMap);
  }

  const onOverlayClick = (overlayType) => {
    if (overlayType === overlay) {
      setOverlay('');
    } else {
      setOverlay(overlayType)
    }
  }

  const onVideoPlayerClick = React.useCallback((event) => {
    if (loaded && musicState !== 'stopped') {
      dispatch({ type: 'MUSIC', payload: { musicState: 'paused' }})
    }
    setOverlay('video');
    setVideoPlaying(event.currentTarget.dataset.id)
  }, [dispatch, loaded, musicState])

  const onVideoPlayerClose = () => {
    if (loaded && musicState !== 'stopped') {
      dispatch({ type: 'MUSIC', payload: { musicState: 'playing' }})
    }
    setOverlay('');
    setVideoPlaying('')
  }

  const getVideoUrl = () => {
    const currentVideo = videos.find((video) => video.videoId === videoPlaying);
    return currentVideo ? currentVideo.videoSrc : '';
  }

  const onContentScroll = () => {
    if (containerRef && containerRef.current) {
      const el = containerRef.current;
      if (el.clientHeight === Math.floor(el.scrollHeight - el.scrollTop)) {
        setIsContentScrolled(true)
      } else {
        setIsContentScrolled(false)
      }
    }
  }

  return (
    <main className={getClasses([
      {[`fp-viewing-${anchors[currentView]}`]: true},
      {'show-map hide-everything': showMap},
      {'show-video hide-content': overlay.length},
      {'hide-everything': overlay === 'about'},
      {'lang-ge': true}
    ])}>
      <Helmet
      data-lang="en"
      >
          <meta charSet="utf-8" />
          <title>აგვისტოს ომი 2008</title>
          <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, shrink-to-fit=yes" />
          <meta name="apple-mobile-web-app-capable" content="yes" />

          <meta name="author" content="Hizan" />

          <link href="https://fonts.googleapis.com/css?family=Noto+Sans&amp;subset=cyrillic,cyrillic-ext,devanagari,greek,greek-ext,latin-ext,vietnamese" rel="stylesheet" />
          <meta name="description" content="პატარა ომი, რომელმაც ვერ შეძრა მსოფლიო" />
          <meta name="keywords" content="აგვისტოს, აგვისტო, ომი, რუსეთი, საქართველო, ოსეთი, სამხრეთ, ურთიერთობები, სააკაშვილი, კოკოითი, ოკუპაცია, გორი, ცხინვალი" />
          <meta property="og:image" content="ogimage.png" />
          <script data-lang="ge" async={true} defer={true} crossOrigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&amp;version=v6.0&amp;appId=2186738638294469&amp;autoLogAppEvents=1" />
      </Helmet>

      <div className="radio">
        <a href="http://radiotavisupleba.ge" target="_blank" rel="noreferrer">
          <Radio />
        </a>

      </div>
      <MenuTopMobile currentOverlay={overlay} onClickShare={() => onOverlayClick('share')} onLanguageClick={() => onOverlayClick('language')} show={showMobile} />

      <ProgressBar activeDay={anchors[currentView]}/>
      <NavigationTop onHashChange={onHashChange} />
      <PlaySound loaded={loaded} />
      <NavigationMobile currentView={currentView} changeCurrentView={changeCurrentView} currentDay={anchors[currentView]} loaded={loaded} show={showMobile} width={width} />
      <Playlist activeDay={anchors[currentView]} onVideoPlayerClick={onVideoPlayerClick} opened={!showMap} />

      <Backgrounds activeDay={anchors[currentView]} />

      <MapContent activeDay={anchors[currentView]} lang="ge" show={showMap} />
      <VideoPlayer activeDay={anchors[currentView]} overlay={overlay} onVideoClose={onVideoPlayerClose} videoUrl={getVideoUrl()} />
      <MapButton onClick={onMapButtonClick} />
      <Statistics activeDay={anchors[currentView]} />
      <div className="about doc-1" onClick={() => setOverlay('about')}>
        პროექტის შესახებ
      </div>

      <div className="facebook">
        <div className="fb-share-button" data-href="http://augustwar.ge/" data-layout="button" data-size="small" data-mobile-iframe="false"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Faugustwar.ge%2F&amp;src=sdkpreparse" rel="noreferrer" className="fb-xfbml-parse-ignore">Share</a></div>
      </div>
      <div className="right-border"></div>
      <div
        {...handlers}
        >
        <div
          onScroll={onContentScroll}
          className="container"
          ref={containerRef}
        >
          <StoryNavigation
            onClickDown={onClickDown}
            onClickUp={onClickUp}
          />
          <Content
            contentMap={dayContent}
            currentView={currentView}
            onVideoPlayerClick={onVideoPlayerClick}
            videoPlaying={videoPlaying}
            showMap={showMap}
          />
          </div>
        </div>
      {
        currentView === 83 && (
          <div className="stats-video">
            <video id="video1" className="video1 video-resize" autoPlay loop muted>
                <source src={Video2SrcWebm} type="video/webm" />
                <source src={Video2SrcMp4} type="video/mp4" />
              </video>
          </div>
        )
      }
      {showMobile ? <div className="bottom-nav">
        <button className={`bottom-nav--item${currentView === 0 ? ' disabled' : ''}${overlay === 'maps' ? ' active' : ''}`} onClick={() => onOverlayClick('maps')}>რუკა <MapsMenu /></button>
        <a href={'#intro'} className={`bottom-nav--item${currentView === 0 ? ' disabled' : ''}${overlay === 'maps' ? ' active' : ''}`}>
          <HomeIcon />
        </a>
        <button
          className={
            `bottom-nav--item${currentView === 0 || currentView === anchors.length - 1 || currentView === anchors.length - 2 ? ' disabled' : ''}${overlay === 'victims' ? ' active' : ''}`
          }
          onClick={() => onOverlayClick('victims')}
        >
          მსხვერპლი <Victims />
        </button>
      </div> : null}
      {showMobile ? <DayNavigation currentDay={anchors[currentView]} isContentScrolled={isContentScrolled} goToPrevious={goToPrevious} goToNext={goToNext} /> : null}
    </main>
  )
}

export default IndexPage
